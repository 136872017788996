<template>
  <div class="d-flex mt-2 rounded bg-white p-3">
    <div class="w-100">
      <div class="row">
        <div
          class="col"
        >
          <div class="font-weight-bold">
            Номер консультации
          </div>

          <div class="mt-1">
            {{ doctorEvaluation.consultationId }}
          </div>
        </div>

        <div
          class="col"
        >
          <div class="font-weight-bold">
            ФИО врача
          </div>

          <div class="mt-1">
            {{ doctorEvaluation.doctorName }}
          </div>
        </div>

        <div
          class="col"
        >
          <div class="font-weight-bold">
            Дата закрытия
          </div>

          <div class="mt-1">
            {{ closeDate }}
          </div>
        </div>
        <div
          v-if="doctorEvaluation.evaluatedAt"
          class="col"
        >
          <div class="font-weight-bold">
            Дата оценки
          </div>

          <div class="mt-1">
            {{ evaluatedAt }}
          </div>
        </div>
        <div
          v-else
          class="col"
        />
      </div>
      <div class="row mt-3">
        <div
          class="col"
        />
        <div
          class="col"
        />
        <div
          class="col"
        />
        <div
          v-if="doctorEvaluation.crmUser"
          class="col"
        >
          <div class="font-weight-bold">
            Кто оценивал
          </div>

          <div class="mt-1">
            {{ assessedFio }}
          </div>
        </div>
        <div
          v-else
          class="col"
        />
      </div>
      <div class="row mt-3">
        <div
          v-if="doctorEvaluation.agreeComment"
          class="col col-6"
        >
          <div class="font-weight-bold">
            Комментарий согласия
          </div>

          <div class="mt-1">
            {{ doctorEvaluation.agreeComment || '-' }}
          </div>
        </div>

        <div
          v-if="doctorEvaluation.agreetDate"
          class="col"
        >
          <div class="font-weight-bold">
            Дата согласия
          </div>

          <div class="mt-1">
            {{ agreetDate }}
          </div>
        </div>
        <div
          v-else
          class="col"
        />

        <div
          v-if="doctorEvaluation.isAgree != null"
          class="col"
        >
          <div class="font-weight-bold">
            Статус согласия
          </div>

          <div
            class="mt-1"
            :class="doctorEvaluationColor"
          >
            {{ doctorEvaluationText }}
          </div>
        </div>
        <div
          v-else
          class="col"
        />
      </div>
    </div>

    <div class="ml-4">
      <b-button
        v-b-tooltip.hover.left
        :title="doctorEvaluation.id ? 'Просмотр оценки консультации' : 'Оценить консультацию врача'"
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        @click="openDoctorEvaluationModal"
      >
        <b-icon
          :icon="doctorEvaluation.id ? 'eye' : 'pencil'"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import { parseISO, format } from '@evd3v/date-fns';

export default {
  name: 'DoctorEvaluationItem',
  props: {
    doctorEvaluation: {
      type: Object,
      default: () => ({
        title: '',
      }),
    },
  },
  computed: {
    closeDate() {
      return this.doctorEvaluation.closeDate
        ? `${format(parseISO(this.doctorEvaluation.closeDate), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    evaluatedAt() {
      return this.doctorEvaluation.evaluatedAt
        ? `${format(parseISO(this.doctorEvaluation.evaluatedAt), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    assessedFio() {
      const { firstName, lastName } = this.doctorEvaluation.crmUser;
      return `${lastName} ${firstName}`;
    },
    agreetDate() {
      if (!this.doctorEvaluation.agreetDate) return '-';
      const date = new Date(this.doctorEvaluation.agreetDate);
      const localTimeZoneOffsetMilliseconds = new Date().getTimezoneOffset() * -1 * 60 * 1000;
      const dateInLocalTimeZome = new Date(+date + localTimeZoneOffsetMilliseconds);

      return format(dateInLocalTimeZome, 'dd.MM.yyyy HH:mm');
    },
    doctorEvaluationText() {
      if (this.doctorEvaluation.isAgree === true) return 'Согласен';
      if (this.doctorEvaluation.isAgree === false) return 'Не согласен';
      return '-';
    },
    doctorEvaluationColor() {
      if (this.doctorEvaluation.isAgree === true) return 'text-success';
      if (this.doctorEvaluation.isAgree === false) return 'text-danger';
      return '';
    },
  },
  methods: {
    openDoctorEvaluationModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorEvaluationModal',
        props: {
          doctorEvaluation: this.doctorEvaluation,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
